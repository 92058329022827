var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "candidate-labels" }, [
    _c(
      "ul",
      { staticClass: "candidate-label-list" },
      [
        _c(
          "li",
          [
            _c(
              "el-popover",
              {
                ref: "candidateDetailTag",
                attrs: {
                  width: "230",
                  placement: "bottom-start",
                  "popper-class": "label-popper",
                  trigger: "click",
                  "visible-arrow": false,
                },
                model: {
                  value: _vm.visible,
                  callback: function ($$v) {
                    _vm.visible = $$v
                  },
                  expression: "visible",
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.labelLoading,
                        expression: "labelLoading",
                      },
                    ],
                    staticClass: "label-container",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "label-manage-btn",
                        class: { "is-btn-guide": _vm.labelManagResumeGuide },
                        on: { click: _vm.showLabelManagement },
                      },
                      [
                        _vm._v(
                          "\r\n                        标签管理\r\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "el-input",
                      {
                        staticClass: "label-input",
                        attrs: { placeholder: "添加/创建标签", maxLength: 20 },
                        on: { input: _vm.labelListFilter },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.addInputLabel.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.labelInput,
                          callback: function ($$v) {
                            _vm.labelInput = $$v
                          },
                          expression: "labelInput",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "append",
                            icon: "el-icon-circle-plus-outline",
                          },
                          on: { click: _vm.addInputLabel },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                    _vm.labelFilterList.length > 0
                      ? _c(
                          "ul",
                          { staticClass: "label-list" },
                          [
                            _vm._l(_vm.labelFilterList, function (category) {
                              return category.tagDataList &&
                                category.tagDataList.length > 0
                                ? [
                                    _c(
                                      "p",
                                      {
                                        key: "category-" + category.categoryId,
                                        staticClass: "label-category",
                                      },
                                      [_vm._v(_vm._s(category.categoryName))]
                                    ),
                                    _vm._l(
                                      category.tagDataList,
                                      function (tag) {
                                        return _c(
                                          "li",
                                          {
                                            key: "tag-" + tag.tagId,
                                            staticClass: "label-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleLabel(tag)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "label-text" },
                                              [_vm._v(_vm._s(tag.tagName))]
                                            ),
                                            _vm.checkIfLabelSelected(tag)
                                              ? _c("i", {
                                                  staticClass:
                                                    "label-icon el-icon-check",
                                                })
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                  ]
                                : _vm._e()
                            }),
                          ],
                          2
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "create-label-btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.addInputLabel },
                          },
                          [
                            _vm._v(
                              "\r\n                        创建\r\n                    "
                            ),
                          ]
                        ),
                    _vm.labelManagResumeGuide
                      ? _c("div", { staticClass: "step-guide" }, [
                          _c("span", { staticClass: "step-guide-box" }, [
                            _c("span", { staticClass: "guide-content" }, [
                              _vm._v(
                                "\r\n                                点击标签管理，即可对标签进行分类、编辑和删除。\r\n                            "
                              ),
                            ]),
                            _c("span", { staticClass: "guide-btn" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "text-operate-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.hideLabelManagResumeGuide(true)
                                    },
                                  },
                                },
                                [_vm._v("不再提醒")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "text-operate-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.hideLabelManagResumeGuide(
                                        false
                                      )
                                    },
                                  },
                                },
                                [_vm._v("好的")]
                              ),
                            ]),
                          ]),
                          _c("span", { staticClass: "step-guide-arrows" }),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.labelManagResumeGuide
                  ? _c("div", { staticClass: "label-management-guide-modal" })
                  : _vm._e(),
                _c(
                  "span",
                  { attrs: { slot: "reference" }, slot: "reference" },
                  [
                    _c("el-button", { staticClass: "add-label-button" }, [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(
                        "\r\n                        标签分类\r\n                    "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm._l(_vm.candidateLabels, function (label, index) {
          return _c("li", { key: index, staticClass: "candidate-label-item" }, [
            _c("span", { staticClass: "candidate-label-text" }, [
              _vm._v(_vm._s(label)),
            ]),
            _c("i", {
              staticClass: "el-icon-close",
              on: {
                click: function ($event) {
                  return _vm.deleteCandidateLabel(label, true)
                },
              },
            }),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }