var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "给候选人发邮件",
        visible: _vm.dialogVisible,
        width: "650px",
        "custom-class": "mail-modal",
        "before-close": _vm.onCancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "mailForm",
          staticClass: "mailDialog-form",
          attrs: {
            model: _vm.ruleForm,
            "label-width": "100px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件地址", prop: "recipients" } },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-left": "20px" },
                        attrs: {
                          id: "emailTxt",
                          name: "recipients",
                          placeholder: "收件地址",
                        },
                        model: {
                          value: _vm.ruleForm.recipients,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "recipients", $$v)
                          },
                          expression: "ruleForm.recipients",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮件标题", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-left": "20px" },
                        attrs: {
                          id: "titleTxt",
                          name: "title",
                          placeholder: "请输入给候选人发送的邮件标题",
                        },
                        model: {
                          value: _vm.ruleForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "title", $$v)
                          },
                          expression: "ruleForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮件正文", prop: "content" } },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-left": "20px" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 4, maxRows: 6 },
                          id: "content",
                          name: "content",
                          placeholder: "请输入需要和候选人沟通的详细内容",
                        },
                        model: {
                          value: _vm.ruleForm.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "content", $$v)
                          },
                          expression: "ruleForm.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
            [_vm._v("发送")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }