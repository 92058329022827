<template>
    <el-dialog
        title="给候选人发邮件"
        :visible.sync="dialogVisible"
        width="650px"
        v-loading="loading"
        custom-class="mail-modal"
        :before-close="onCancel"
        >
        <el-form ref="mailForm" :model="ruleForm" label-width="100px" :rules="rules" class="mailDialog-form">
            <el-row>
                <el-col :span="18">
                    <el-form-item label="收件地址" prop="recipients">
                        <el-input v-model="ruleForm.recipients" id="emailTxt" name="recipients" placeholder="收件地址" style="margin-left:20px;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="邮件标题" prop="title">
                        <el-input v-model="ruleForm.title" id="titleTxt" name="title" placeholder="请输入给候选人发送的邮件标题" style="margin-left:20px;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="邮件正文" prop="content">
                        <el-input v-model="ruleForm.content" type="textarea" :autosize="{ minRows: 4, maxRows: 6}" id="content" name="content" placeholder="请输入需要和候选人沟通的详细内容" style="margin-left:20px;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onCancel">取消</el-button>
            <el-button type="primary" @click="onConfirm">发送</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                ruleForm:{
                    recipients:'',
                    title:'',
                    content:'',
                    ResumeName:'',
                    ResumeEmail:'',
                },
                rules: {
                    recipients: [
                        { required: true, message: '请填写收获地址', trigger: 'blur' }
                    ],
                    title: [
                        { required: true, message: '请填写邮件标题', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '请填写邮件正文', trigger: 'blur' }
                    ],
                },
                loading:false,
                dialogVisible:false,
            }
        },
        props: {
            candidateId: String,
            candidateEmail: String,
            candidateName:String,
        },
        mounted() {
            // this.modal = this.$refs.modal;
        },
        methods: {
            onConfirm(vm){
                this.$refs.mailForm.validate((valid) => {
                    if(valid){
                        let emailTxt = $('#emailTxt').val();
                        let mailReg = /(^\s*[a-zA-Z0-9_%+#&'*/=^`{|}~-](?:\.?[a-zA-Z0-9_%+#&'*/=^`{|}~-])*@(?:[a-zA-Z0-9_](?:(?:\.?|-*)[a-zA-Z0-9_])*\.[a-zA-Z]{2,9}|\[(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)])\s*$)/;
                        let emailvalid = emailTxt.split(';').every(item => {
                            return mailReg.test(item);
                        })
                        if (emailvalid) {
                            this.loading = true;
                            this.ruleForm.ResumeEmail = this.candidateEmail;
                            this.ruleForm.ResumeName = this.candidateName;
                            _request({
                                url: `/Headhunting/Candidate/SendEmailNotification`,
                                method: 'POST',
                                data: this.ruleForm
                            }).then((res) => {
                                if(res.succeeded){
                                    this.onCancel();
                                    shortTips('发送邮件成功');
                                }else{
                                    shortTips(res.message);
                                }
                            }).catch((err) => {
                                console.log(err);
                            }).finally(()=>{
                                this.loading = false;
                            })
                        } else {
                            shortTips('请输入正确的收件人地址,多个用分号隔开');
                        }
                    }
                })

            },
            show(){
                this.dialogVisible = true;
            },
            onCancel(){
                this.dialogVisible = false;
                this.$refs.mailForm.resetFields();
            },
            emailByTable(json){
                // this.ruleForm.candidateId = json.candidateId;
                this.ruleForm.recipients = json.emailAddress;
                this.show();
            }
        }
    }
</script>

<style lang="scss">
@import './mail-dialog.scss';
.mailDialog-form{
    .el-form-item__error{
        margin-left: 20px;
    }
}
</style>
