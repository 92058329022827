<template>
<div class="candidate-labels">
    <ul class="candidate-label-list">
        <li>
            <el-popover
                ref="candidateDetailTag"
                width="230"
                placement="bottom-start"
                popper-class="label-popper"
                trigger="click"
                :visible-arrow="false"
                v-model="visible"
            >
                <div
                    class="label-container"
                    v-loading="labelLoading">
                    <span
                        class="label-manage-btn"
                        :class="{'is-btn-guide': labelManagResumeGuide}"
                        @click="showLabelManagement">
                        标签管理
                    </span>
                    <el-input
                        placeholder="添加/创建标签"
                        v-model="labelInput"
                        class="label-input"
                        @input="labelListFilter"
                        @keyup.enter.native="addInputLabel"
                        :maxLength="20"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-circle-plus-outline"
                            @click="addInputLabel"
                        ></el-button>
                    </el-input>
                    <ul class="label-list" v-if="labelFilterList.length > 0">
                        <template v-for="category in labelFilterList" v-if="category.tagDataList && category.tagDataList.length > 0">
                            <p class="label-category" :key="'category-' + category.categoryId">{{category.categoryName}}</p>
                            <li
                                class="label-item"
                                v-for="tag in category.tagDataList"
                                :key="'tag-' + tag.tagId"
                                @click="handleLabel(tag)"
                            >
                                <span class="label-text">{{tag.tagName}}</span>
                                <i class="label-icon el-icon-check" v-if="checkIfLabelSelected(tag)"></i>
                            </li>
                        </template>
                    </ul>
                    <el-button
                        class="create-label-btn"
                        type="primary"
                        @click="addInputLabel"
                        v-else
                    >
                        创建
                    </el-button>
                    <div class="step-guide" v-if="labelManagResumeGuide">
                        <span class="step-guide-box">
                            <span class="guide-content">
                                点击标签管理，即可对标签进行分类、编辑和删除。
                            </span>
                            <span class="guide-btn">
                                <span class="text-operate-btn" @click="hideLabelManagResumeGuide(true)">不再提醒</span>
                                <span class="text-operate-btn" @click="hideLabelManagResumeGuide(false)">好的</span>
                            </span>
                        </span>
                        <span class="step-guide-arrows"></span>
                    </div>
                </div>
                <div class="label-management-guide-modal" v-if="labelManagResumeGuide"></div>
                <span slot="reference">
                    <el-button class="add-label-button">
                        <i class="el-icon-plus"></i>
                        标签分类
                    </el-button>
                </span>
            </el-popover>
        </li>
        <li
            class="candidate-label-item"
            v-for="(label, index) in candidateLabels"
            :key="index"
        >
            <span class="candidate-label-text">{{label}}</span>
            <i class="el-icon-close" @click="deleteCandidateLabel(label, true)"></i>
        </li>
    </ul>
</div>
</template>
<script>
import { candidate as candidateUrl } from '#/js/config/api.json';
import tagService from '#/js/service/tagService.js';
export default {
    props: {
        resumeId: String
    },
    data() {
        return {
            candidateLabels: [],
            labelInput: "",
            labelFilterList: [],
            labelList: [],
            labelLoading: false,
            visible: false,
            labelManagResumeGuide: false,
            hasShowLabelGuide: false
        }
    },
    computed: {
        candidateResumeId() {
            return this.resumeId ? this.resumeId : this.$route.params.resumeId;
        }
    },
    mounted() {
        this.getCandidateLabels();
        this.getResumeLabel();
    },
    methods: {
        getCandidateLabels(cd) {
            tagService.getResumeTagList({
                resumeIds: [this.candidateResumeId]
            }).then(res =>{
                if(res && res.length > 0 && res[0].tagList && res[0].tagList.length > 0) {
                    let list = [];
                    res[0].tagList.forEach(tag => {
                        list.push(tag.tagName);
                    });
                    this.candidateLabels = list;
                    if(cd) {
                        cd();
                    }
                } else {
                    this.candidateLabels = [];
                }
                if(!localStorage.getItem('labelManagResumeGuide') && !this.hasShowLabelGuide) {
                    this.$nextTick(() => {
                        this.visible = true;
                        this.labelManagResumeGuide = true;
                        this.hasShowLabelGuide = true;
                    })
                }
            })
        },
        getResumeLabel() {
            this.labelLoading = true;
            tagService.getTagList()
                .then(res =>{
                    this.labelLoading = false;
                    this.labelList = res.tagList;
                    this.labelFilterList = res.tagList;
                }).catch(err =>{
                    this.labelLoading = false;
                })
        },
        checkIfLabelSelected(label) {
            let result = false;
            result = this.candidateLabels.some(item => {
                if(item == label.tagName) {
                    return true;
                }
            })
            return result;
        },
        labelListFilter(val) {
            if(!val) {
                this.labelFilterList = this.labelList;
                return;
            }
            let tagDataList = [],
                labelFilterList = [];
            this.labelList.forEach(category =>{
                if(category.tagDataList) {
                    tagDataList = category.tagDataList.filter(tag => {
                        return tag.tagName.toLowerCase().indexOf(val.toLowerCase()) > -1
                    })
                    labelFilterList.push({
                        categoryId: category.categoryId,
                        categoryName: category.categoryName,
                        tagDataList: tagDataList
                    })
                }
            })
            this.labelFilterList = labelFilterList;
        },
        deleteCandidateLabel(label, isCandidateLabel) {
            this.labelLoading = true;
            _request({
                url: candidateUrl.remove_itap.replace(/\%p/, this.candidateResumeId).replace(/\%p/, label),
                method: 'DELETE'
            }).then(res => {
                this.$emit('collect-label-refresh');
                if(!isCandidateLabel) {
                    this.visible = false;
                }
                this.getCandidateLabels();
                this.getResumeLabel();
            }).finally(() =>{
                this.labelLoading = false;
            });
        },
        handleLabel(label) {
            if(this.checkIfLabelSelected(label)) {
                this.deleteCandidateLabel(label.tagName);
            } else {
                this.addCandidateLabel(label.tagName);
            }
        },
        addCandidateLabel(label) {
            this.labelLoading = true;
            _request({
                method: 'POST',
                url: candidateUrl.add_itap.replace(/\%p/, this.candidateResumeId),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: {
                    objectIds: this.candidateResumeId,
                    tagsName: label
                }
            }).then(() => {
                this.labelInput = "";
                this.$emit('collect-label-refresh');
                this.visible = false;
                this.getCandidateLabels(() => {
                    this.visible = true;
                });
                this.getResumeLabel();
            }).finally(() =>{
                this.labelLoading = false;
            });
        },
        addInputLabel() {
            if(!this.labelInput) {
                shortTips('标签名不能为空');
                return false;
            } else {
                this.addCandidateLabel(this.labelInput);
            }
        },
        showLabelManagement() {
            this.visible = false;
            this.$emit('showLabelManagement');
        },
        hideLabelManagResumeGuide(isNoMore) {
            if(isNoMore) {
                localStorage.setItem('labelManagResumeGuide', true)
            }
            this.visible = false;
            this.labelManagResumeGuide = false;
        }
    }
}
</script>

<style lang="scss" scope>
    .candidate-labels {
        .candidate-label-list {
            display: flex;
            margin-bottom: 0;
            flex-wrap: wrap;
            padding-top: 2px;
            padding-bottom: 15px;
            border-bottom: 1px solid #EBEBEB;
            .candidate-label-item {
                display: inline-flex;
                align-items: center;
                min-width: 20px;
                height: 26px;
                line-height: 34px;
                padding: 0 10px;
                margin-right: 10px;
                margin-bottom: 5px;
                text-align: center;
                border-radius: 5px;
                font-size: 14px;
                background-color: #F1FFFC;
                color: $primary;
                border: 1px solid #38BC9D;
                position: relative;
                .candidate-label-text {
                    display: inline-block;
                    max-width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .el-icon-close {
                    margin-left: 6px;
                    color: #38BC9D;
                    font-weight: 600;
                    cursor: pointer;
                }
                &:hover {
                    background-color: #e3f6f1;
                }
            }
        }
        .add-label-button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
            padding: 0 8px 0 10px;
            min-width: 20px;
            height: 26px;
            border-radius: 3px;
            font-size: 14px;
            color: $primary;
            border: 1px solid $primary;
            background-color: #fff;
            &:hover {
                background-color: $primary;
                color: #fff;
            }
        }
        .el-icon-plus {
            font-weight: 600;
        }
        .add-label-icon {
            color: #bbb;
            font-size: 16px;
        }
    }
</style>
<style lang="scss">
.label-popper.el-popper {
    padding: 0;
    box-shadow: 0px 0px 4px 0 rgba(0,0,0,0.5);
    border-radius: 2px;
    .label-container {
        position: relative;
        width: 230px;
        padding-bottom: 28px;
        .label-manage-btn {
            position: absolute;
            right: 8px;
            bottom: 0;
            text-align: right;
            font-size: 12px;
            color: #4A90E2;
            padding-bottom: 6px;
            cursor: pointer;
            &.is-btn-guide {
                z-index: 4000;
                background-color: #fff;
                display: inline-block;
                padding: 0 6px;
                height: 26px;
                line-height: 26px;
                border-radius: 4px;
                pointer-events: none;
            }
        }
        .label-input.el-input {
            border-bottom: 2px solid #ddd;
            .el-input__inner {
                border: none;
                height: 38px;
                line-height: 38px;
                padding: 0;
                width: 170px;
                padding-left: 15px;
                color: #666;
            }
            .el-input-group__append {
                border: none;
                height: 38px;
                line-height: 38px;
                background-color: #fff;
                .el-icon-circle-plus-outline {
                    font-size: 16px;
                    color: $primary;
                }
                .el-button {
                    min-width: unset;
                    padding: 0 12px;
                    border: none;
                    &:hover, &:focus {
                        background-color: #fff;
                    }
                }
            }
        }
        .label-list {
            position: relative;
            max-height: 210px;
            overflow-y: auto;
            margin-bottom: 0;
            padding-top: 4px;

            .label-category {
                height: 32px;
                line-height: 32px;
                font-size: 14px;
                color: #666;
                font-weight: bold;
                margin: 0;
                padding-left: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .label-item {
                height: 32px;
                line-height: 32px;
                padding-left: 30px;
                position: relative;
                display: flex;
                cursor: pointer;
                &::before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    display: inline-block;
                    background-color: $primary;
                    position: absolute;
                    top: 12px;
                    left: 14px;
                }
                .label-text {
                    display: inline-block;
                    width: 140px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #666;
                }
                .label-icon {
                    color: #999;
                    font-size: 18px;
                    line-height: 32px;
                    text-align: center;
                    flex-grow: 1;
                }
            }
        }
        .create-label-btn {
            margin: 22px 12px;
            width: 206px;
            height: 38px;
            line-height: 38px;
            padding: 0;
        }
        .step-guide {
            position: absolute;
            z-index: 4001;
            left: 291px;
            bottom: -30px;
            font-size: 14px;
            line-height: 24px;
            font-weight: normal;
            .step-guide-box {
                position: relative;
                display: inline-block;
                width: 340px;
                height: 72px;
                border: 2px dashed rgba(0, 0, 0, 0.4);
                background-color: #fff;
                border-radius: 12px;
                padding: 10px;
            }
            .guide-content {
                color: #666;
                line-height: 24px;
            }
            .guide-btn {
                position: absolute;
                bottom: 10px;
                right: 18px;
                .text-operate-btn + .text-operate-btn {
                    margin-left: 10px;
                }
            }
            .step-guide-arrows {
                position: absolute;
                top: 27px;
                left: -62px;
                width: 62px;
                height: 2px;
                border-top: 2px dashed #fff;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2px;
                    height: 18px;
                    background-color: #fff;
                    transform: rotate(-60deg);
                    transform-origin: top left;
                }
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 2px;
                    left: 0;
                    width: 2px;
                    height: 18px;
                    background-color: #fff;
                    transform: rotate(60deg);
                    transform-origin: bottom left;
                }
            }
        }
    }
    .label-management-guide-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
    }
}
</style>